<script lang="ts">
  import { prominent } from "color.js";
  import copy from "copy-to-clipboard";
  import { toasts } from "svelte-toasts";

  export let imageUrl: string;

  function copyColor(color: string) {
    if (copy(color)) {
      toasts.add({
        title: "Color copied!",
        description: color,
        type: "info",
      });
    }
  }

  const options = { amount: 8, format: "hex", group: 40, sample: 2 };
  async function getPalette(url: string) {
    return (await prominent(url, options)) as string[];
  }
</script>

{#if imageUrl}
  {#await getPalette(imageUrl)}
    <div />
  {:then colors}
    <div class="flex h-36">
      {#each colors as color}
        <div
          class="flex-grow cursor-pointer"
          style="background-color:{color}"
          title="Click to copy color {color.toString()}"
          on:click={() => copyColor(color.toString())}
        >
          &nbsp;
        </div>
      {/each}
    </div>
  {/await}
{/if}
