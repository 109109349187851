<script lang="ts">
  import { ToastContainer, FlatToast } from "svelte-toasts";

  import Header from "./Header.svelte";
  import Footer from "./Footer.svelte";
  import Search from "./Search.svelte";

  const divClass = "p-4 border-4 rounded-lg bg-white flex-shrink";
  const imgClass = "min-w-0 min-h-0";
</script>

<Header />

<main class="flex-grow">
  <Search>
    <div class="flex p-4 gap-4">
      <div class={divClass}>
        <img src="/assets/hello-kitty.png" alt="" class={imgClass} />
      </div>
      <div class={divClass}>
        <img src="/assets/spiderman.png" alt="" class={imgClass} />
      </div>
      <div class={divClass}>
        <img src="/assets/groot.png" alt="" class={imgClass} />
      </div>
    </div>
  </Search>
</main>

<ToastContainer theme="light" placement="bottom-right" let:data>
  <FlatToast {data} />
  <!-- Provider template for your toasts -->
</ToastContainer>

<Footer />

<style global lang="postcss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
