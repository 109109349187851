<header class="text-gray-600 body-font">
  <div
    class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center"
  >
    <a
      href="/"
      class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
    >
      <img
        src="/assets/android-chrome-192x192.png"
        height="40"
        width="40"
        alt="Palitra App"
      />
      <span class="ml-3 text-xl">Palitra App</span>
    </a>
    <nav
      class="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400	flex flex-wrap items-center text-base justify-center"
    >
      &nbsp;
      <!-- <a class="mr-5 hover:text-gray-900">First Link</a>
      <a class="mr-5 hover:text-gray-900">Second Link</a>
      <a class="mr-5 hover:text-gray-900">Third Link</a>
      <a class="mr-5 hover:text-gray-900">Fourth Link</a> -->
    </nav>

    <!-- Buymeacoffee -->
    <a
      href="https://www.buymeacoffee.com/pyoner?ref=palitra.app"
      target="_blank"
      class="p-2"
      ><img
        src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
        alt="Buy Me A Coffee"
        class="h-14"
      /></a
    >

    <!-- Producthunt -->
    <a
      href="https://www.producthunt.com/posts/palitra-app?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-palitra-app"
      target="_blank"
      class="p-2"
      ><img
        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=315084&theme=light"
        alt="Palitra App - The easiest way to find a color palette! | Product Hunt"
        class="h-14"
      /></a
    >
  </div>
</header>
