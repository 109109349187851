<script lang="ts">
  import Result from "./Result.svelte";
  import type { Result as ResultType } from "./types";
  export let results: ResultType[];
</script>

{#if results}
  <div class="py-5 flex flex-wrap">
    {#each results as result}
      <Result {result} />
    {/each}
  </div>
{/if}
