<script lang="ts">
  import type { Result } from "./types";
  import Results from "./Results.svelte";

  const ready = (
    gname: string,
    query: string,
    promos: unknown[],
    results: Result[],
    div: HTMLElement
  ) => {
    new Results({ target: div, props: { results } });
    return true;
  };

  // @ts-ignore
  window.__gcse || (window.__gcse = {});
  // @ts-ignore
  window.__gcse.searchCallbacks = {
    image: {
      ready,
    },
  };

  const url = new URL(document.URL);
  let query = url.searchParams.get("q") || "hello kitty";

  let showPlaceholder = true;
  function search() {
    showPlaceholder = false;
    //@ts-ignore
    const cse = google.search.cse.element.getElement("default");
    cse.execute(query);
  }
</script>

<section class="text-gray-600 body-font bg-gray-100">
  <form
    on:submit|preventDefault={search}
    class="container mx-auto flex px-5 py-12 md:flex-row flex-col items-center"
  >
    <div
      class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center"
    >
      <h1
        class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900"
      >
        The easiest way to find a color palette!
      </h1>
      <p class="mb-8 leading-relaxed">Just Google It!</p>
      <div class="flex w-full md:justify-start justify-center items-end">
        <div class="relative mr-4 md:w-full lg:w-full xl:w-1/2 w-2/4">
          <input
            bind:value={query}
            type="text"
            class="w-full rounded border bg-opacity-50 border-gray-300 focus:ring-2 focus:ring-blue-200 focus:border-blue-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          />
        </div>
        <button
          on:click={search}
          class="inline-flex text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg"
          >Find</button
        >
      </div>
      <p class="text-sm mt-2 text-gray-500 mb-8 w-full">
        Enter keywords, plus use any Google Search <a
          class="text-blue-500"
          href="https://support.google.com/websearch/answer/2466433"
          target="_blank">operators</a
        >.
      </p>
    </div>
  </form>
</section>

<div class="container mx-auto">
  <!-- Place this tag where you want both of the search box and the search results to render -->
  <div
    class="gcse-searchresults-only"
    data-gname="default"
    data-disableWebSearch="true"
    data-enableImageSearch="true"
  />

  <!-- Placeholder -->
  {#if showPlaceholder}
    <slot />
  {/if}
</div>
