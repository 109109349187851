<script lang="ts">
  import Palette from "./Palette.svelte";
  import type { Result } from "./types";

  export let result: Result;
</script>

<div class="p-4 w-full lg:w-1/2 xl:w-1/3">
  <div
    class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
  >
    <Palette imageUrl={result.thumbnailImage.url} />
    <div class="p-6">
      <h2
        class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
      >
        {result.fileFormat}
      </h2>
      <h1 class="title-font text-lg font-medium text-gray-900 mb-3 truncate">
        {result.titleNoFormatting}
      </h1>
      <p class="leading-relaxed mb-3">
        <a
          href={result.image.url}
          target="_blank"
          title={result.titleNoFormatting}
        >
          <img
            class="inline-block h-20 w-20 rounded-full border-2 object-cover"
            src={result.thumbnailImage.url}
            alt={result.titleNoFormatting}
          />
        </a>
      </p>
      <div class="flex items-center flex-wrap ">
        <a
          class="text-blue-500 inline-flex items-center md:mb-2 lg:mb-0"
          href={result.contextUrl}
          target="_blank"
          >Source
          <svg
            class="w-4 h-4 ml-2"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M5 12h14" />
            <path d="M12 5l7 7-7 7" />
          </svg>
        </a>
      </div>
    </div>
  </div>
</div>
