<footer class="text-gray-600 body-font">
  <div class="bg-gray-100">
    <div
      class="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col"
    >
      <a
        href="/"
        class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
      >
        <img
          src="/assets/android-chrome-192x192.png"
          height="40"
          width="40"
          alt="Palitra App"
        />
        <span class="ml-3 text-xl">Palitra App</span>
      </a>
      <p class="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">
        © 2021 Palitra App —
        <a
          href="https://twitter.com/pyoner"
          rel="noopener noreferrer"
          class="text-gray-600 ml-1"
          target="_blank">@pyoner</a
        >
      </p>
    </div>
  </div>
</footer>
